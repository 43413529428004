import React from "react";
import { FaLongArrowAltRight } from "react-icons/fa";
import PropTypes from "prop-types";

import "twin.macro";

import { Container, Heading, Button } from "@components/shared";
import OfferList from "./OfferList";

const OfferIntro = ({ title, button }) => (
  <Container.Area as="section" tw="py-24 lg:py-48">
    <Heading dangerouslySetInnerHTML={{ __html: title }} />
    <OfferList />
    <Button to="/oferta" tw="mt-6">
      {button}
      <FaLongArrowAltRight />
    </Button>
  </Container.Area>
);

OfferIntro.propTypes = {
  title: PropTypes.string.isRequired,
  button: PropTypes.string.isRequired,
};

export default OfferIntro;
