import React from "react";
import PropTypes from "prop-types";

import { AnchorLink } from "gatsby-plugin-anchor-links";

import tw, { styled } from "twin.macro";

import shortid from "shortid";

import { urlify } from "@helpers";
import { useOfferList } from "@queries";

const OfferList = ({ className }) => {
  const offerList = useOfferList();

  return (
    <ul className={className}>
      {offerList.map((offer) => (
        <li key={shortid.generate()}>
          <AnchorLink to={`/oferta#${urlify(offer.title)}`} stripHash>
            {offer.title}
          </AnchorLink>
        </li>
      ))}
    </ul>
  );
};

OfferList.propTypes = {
  className: PropTypes.string.isRequired,
};

export default styled(OfferList)`
  ${tw`lg:w-7/10 xl:w-2/3 my-0 mx-auto text-center to-md:leading-9 to-xl:leading-11 xl:leading-13`}

  & li {
    ${tw`xs:inline xs:not-last-of-type:mr-4 to-xs:text-center`}

    &:first-of-type:before {
      ${tw`xs:hidden`}
    }

    & a {
      ${tw`to-lg:text-xl lg:text-3xl font-thin italic no-underline hover:underline break-words lowercase`}
    }
  }
`;
