import React from "react";
import PropTypes from "prop-types";

import tw from "twin.macro";

import { Row, Image, Quote } from "@components/shared";
import shortid from "shortid";

const Hero = ({ images }) => (
  <>
    <div tw="flex items-center justify-center to-lg:my-4 lg:h-hero xxl:h-auto">
      <Quote tw="lg:text-5xl" />
    </div>
    <Row>
      {images.map((image) => (
        <Row.Col key={shortid.generate()} tw="mb-16">
          <Image
            fluid={image.asset.fluid}
            alt={image.alt}
            style={{ height: "90vh" }}
          />
        </Row.Col>
      ))}
    </Row>
  </>
);

Hero.propTypes = {
  images: PropTypes.array.isRequired,
};

export default Hero;
