import React from "react";
import PropTypes from "prop-types";

import shortid from "shortid";

import "twin.macro";

import Testimonial from "./Testimonial";

const Testimonials = ({ testimonials }) => (
  <section tw="flex to-md:flex-col to-xl:flex-wrap justify-center items-center text-center to-sm:py-16 sm:py-24 xl:py-32">
    {testimonials.slice(0, 3).map(({ text, author }) => (
      <Testimonial key={shortid.generate()} quote={text} author={author} />
    ))}
  </section>
);

Testimonials.propTypes = {
  testimonials: PropTypes.array.isRequired,
};

export default Testimonials;
