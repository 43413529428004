import React from "react";
import { FaLongArrowAltRight } from "react-icons/fa";
import PropTypes from "prop-types";

import "twin.macro";

import { Row, Image, Textbox, Heading, Button } from "@components/shared";

const EventsIntro = ({ title, text, button, image }) => (
  <Row as="section" tw="my-16">
    <Row.Col tw="flex flex-col justify-center items-start to-lg:items-center py-16 px-16 to-sm:px-4 to-xl:px-8">
      <Heading dangerouslySetInnerHTML={{ __html: title }} />
      <Textbox blocks={text} />
      <Button to="/wydarzenia" tw="mt-5">
        {button}
        <FaLongArrowAltRight />
      </Button>
    </Row.Col>
    <Row.Col>
      <Image fluid={image.asset.fluid} alt={image.alt} tw="h-full" />
    </Row.Col>
  </Row>
);

EventsIntro.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.array.isRequired,
  button: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
};

export default EventsIntro;
