import React from "react";
import { FaStar } from "react-icons/fa";
import PropTypes from "prop-types";

import "twin.macro";

import { Highlight } from "@components/shared";

const Testimonial = ({ quote, author }) => (
  <div
    tw="
      flex items-center flex-col
      to-md:w-full w-1/2 xl:w-full
      to-xl:not-last-of-type:mb-12 to-sm:p-5 sm:p-10 lg:p-12
      rounded-t-top rounded-b-bottom
    "
  >
    <p tw="relative text-center text-xl italic leading-8 tracking-wider w-full">
      <FaStar
        tw="
          absolute bottom-full left-1/2 -z-1
          text-huge text-primary-pale
          transform -translate-x-1/2 translate-y-1/2
        "
      />
      {quote}
    </p>
    <Highlight tw="mt-3">{author}</Highlight>
  </div>
);

Testimonial.propTypes = {
  quote: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
};

export default Testimonial;
