import React from "react";
import PropTypes from "prop-types";

import { graphql } from "gatsby";
import { LocalBusinessJsonLd } from "gatsby-plugin-next-seo";

import "twin.macro";

import { useSeo } from "@queries";

import { Layout } from "@components/layout";
import {
  Hero,
  OfferIntro,
  EventsIntro,
  Testimonials,
} from "@components/homepage";

const HomePage = ({ data }) => {
  const { title, description } = useSeo();

  const { hero_images, offer, events, testimonials } = data.sanityHomePage;
  const { phone_number, email } = data.sanityContactPage;
  const { images } = data.sanityGalleryPage;

  return (
    <>
      <LocalBusinessJsonLd
        id="https://kulinarnieinaczej.pl"
        name={title}
        description={description}
        url="https://kulinarnieinaczej.pl"
        telephone={phone_number}
        overrides={{
          email,
        }}
        address={{
          streetAddress: "Chrobrego 4a",
          addressLocality: "Biskupiec",
          addressRegion: "warmińsko-mazurskie",
          postalCode: "11-300",
          addressCountry: "PL",
        }}
        geo={{
          latitude: "53.86404302",
          longitude: "20.95884293",
        }}
        images={[...images.map((image) => image.asset.fluid.src)]}
      />

      <Layout showNewsletter>
        <Hero images={hero_images} />
        <OfferIntro title={offer.title} button={offer.button} />
        <EventsIntro
          image={events.image}
          title={events.title}
          text={events._rawText}
          button={events.button}
        />
        {testimonials && !!testimonials.length && (
          <Testimonials testimonials={testimonials} />
        )}
      </Layout>
    </>
  );
};

HomePage.propTypes = {
  data: PropTypes.shape({
    sanityHomePage: PropTypes.shape({
      hero_images: PropTypes.array.isRequired,
      offer: PropTypes.shape({
        title: PropTypes.string.isRequired,
        button: PropTypes.string.isRequired,
      }).isRequired,
      events: PropTypes.shape({
        image: PropTypes.object.isRequired,
        title: PropTypes.string.isRequired,
        _rawText: PropTypes.array.isRequired,
        button: PropTypes.string.isRequired,
      }).isRequired,
      testimonials: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string.isRequired,
          author: PropTypes.string.isRequired,
        }).isRequired
      ).isRequired,
    }).isRequired,
    sanityContactPage: PropTypes.shape({
      phone_number: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
    }).isRequired,
    sanityGalleryPage: PropTypes.shape({
      images: PropTypes.arrayOf(
        PropTypes.shape({
          asset: PropTypes.shape({
            fluid: PropTypes.shape({
              src: PropTypes.string.isRequired,
            }).isRequired,
          }).isRequired,
        }).isRequired
      ).isRequired,
    }).isRequired,
  }).isRequired,
};

export const query = graphql`
  query HomePageQuery {
    sanityHomePage {
      hero_images {
        asset {
          fluid(maxWidth: 1720) {
            base64
            src
            sizes
            aspectRatio
            srcSet
            srcSetWebp
            srcWebp
          }
        }
        alt
      }
      offer {
        title
        button
      }
      events {
        button
        image {
          asset {
            fluid(maxWidth: 1720) {
              base64
              src
              sizes
              aspectRatio
              srcSet
              srcSetWebp
              srcWebp
            }
          }
          alt
        }
        title
        _rawText
      }
      testimonials {
        text
        author
      }
    }

    sanityContactPage {
      phone_number
      email
    }

    sanityGalleryPage {
      images {
        asset {
          fluid {
            src
          }
        }
      }
    }
  }
`;

export default HomePage;
